import * as React from 'react'
import { Dropdown as AntdDropdown } from 'antd'
import type { DropDownProps as AntdDropDownProps } from 'antd'
import type { MenuItemType } from 'antd/lib/menu/hooks/useItems'
import { MoreOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { styledIcon } from 'services/icon'
import { Action } from 'components/ActionDropdown'

// https://github.com/react-component/menu/blob/2b830a14082f9f17148723501f1202aed5f4d368/src/interface.ts#L94
export interface MenuInfo {
  key: string
  keyPath: string[]
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
}

interface DropDownProps {
  items: Action[]
  placement?: string
  className?: string
  onClick?: (info: MenuInfo) => void
}

export default function Dropdown({
  items,
  children,
  placement = 'bottomRight',
  className = '',
  ...props
}: DropDownProps & AntdDropDownProps) {
  const [isOpen, setOpen] = useState(false)
  const content = children || (
    <div className={`hover:cursor-pointer ${className}`}>
      <MoreOutlined />
    </div>
  )

  return (
    <AntdDropdown
      trigger={['click']}
      open={isOpen && items.length > 0}
      onOpenChange={setOpen}
      menu={{
        items: items.map(({ actionKey, ...item }): MenuItemType => ({
          ...item,
          key: actionKey as React.Key,
          // Bigger icons
          ...('icon' in item
            ? { icon: styledIcon(item.icon, { fontSize: '1.1rem' }) }
            : {}
          ),
        })),
      }}
      {...props}
      placement={placement}
    >
      {content}
    </AntdDropdown>
  )
}
