import * as React from 'react'
import { Empty } from 'antd'
import type { Training as TrainingType, UserData } from 'shared/types'
import { useRouteLoaderData } from 'react-router-dom'
import { useI18n } from 'context/I18nProvider'
import useFilters from 'hooks/filters'
import { useInfiniteSWR } from 'hooks/infinite-swr'
import Button from 'components/UI/Button'
import HighLight from 'components/HighLight'
import Section from 'components/UI/Section'
import Spinner from 'components/Spinner'
import { API_TRAININGS } from 'constants/api-v2'
import { messageContext } from 'utils/string'
import Card from './Card'
import FiltersWrapper from './FiltersWrapper'
import OutsideCatalogLink from './OutsideCatalogLink'
import TopSearch from './TopSearch'
import TrainingFilters from '../../components/Filters/TrainingFilters'
import { useTableState } from '../../hooks/useTableState'


const PAGE_SIZE = 30

const Path = ({ category, theme }: { category: string, theme: string }) => {
  const separator = category && theme ? ' - ' : ''
  return (
    <>
      <HighLight title={category}>{category}</HighLight>
      {separator}
      <HighLight title={theme}>{theme}</HighLight>
    </>
  )
}

export default function Training() {
  const { i18n, i18name } = useI18n()
  const { defaultVintage } = useRouteLoaderData('user') as UserData

  const {
    setCurrent,
  } = useTableState({
    defaultPageSize: PAGE_SIZE,
    defaultOrderBy: 'user.fullName',
  })

  const filtersProps = useFilters(
    'training',
    true,
    { ...(defaultVintage.training ? { vintage:
       [{
         value: defaultVintage.training.id.toString(),
         label: i18name(defaultVintage.training),
       }],
    } : {}) },
    () => setCurrent(1),
  )
  const { values, setValue, url: filtersUrl, isInitialized } = filtersProps
  // eslint-disable-next-line max-len
  const params = '?groups[]=trainings:list:cards&status[]=validated&visibleInCatalog=true&fictive=false&translations.name=asc'
  const pagination = `&pagination=true&itemsPerPage=${PAGE_SIZE}`
  const url = `${API_TRAININGS}${params}${pagination}${!!filtersUrl ? `&${filtersUrl}` : ''}`

  const { data, error, hasNext, next, loading } = useInfiniteSWR(url, true)
  // @ts-ignore
  const { results: trainings, totalItems } = data || { results: [], totalItems: 0 }

  if (!isInitialized) {
    return null
  }

  if (!!error) throw new Error(error)

  const empty = !trainings.length

  return (
    <div className="catalog relative flex flex-col pb-8">
      <OutsideCatalogLink />
      <div className="grid grid-cols-4 gap-6">
        <TopSearch
          total={totalItems}
          search={values?.q ?? ''}
          onChange={(val: string) => setValue('q', val)}
          placeholder={i18n('form.trainings_management_search_placeholder')}
          totalText={i18n('catalog.trainings.available')}
        />

        <FiltersWrapper>
          <TrainingFilters {...filtersProps} />
        </FiltersWrapper>

        {(!empty && !loading) && trainings.map((training: TrainingType) => (
          <Card
            key={training['@id']}
            training={training}
            highlight={values?.q?.toString()}
            path={<Path category={i18name(training.category)} theme={i18name(training.theme)} />}
          />
        ))}
        {loading && <div className="col-span-4 flex justify-center"><Spinner /></div>}
        {!loading && empty && <Section className="col-span-4"><Empty /></Section>}
      </div>
      {!empty && hasNext && (
        <Button onClick={next} className="mt-6 self-center">
          {/* @TODO Port infinite-swr to TS */}
          {/* @ts-ignore */}
          {messageContext(i18n('label.infinite.scroll.button'), { nbPerPage: PAGE_SIZE })}
        </Button>
      )}
    </div>
  )
}
