import { LANG_ISO } from 'utils/lang'

export const numberWithCommas = x => x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''

// @see https://stackoverflow.com/a/11832950
export const roundNum = num => Math.round((num + Number.EPSILON) * 100) / 100

export const typeNumberInput = value => {
  if (typeof value === 'number') return value
  if (typeof value === 'string') {
    return Number(value.replace(',', '.'))
  }
  return value
}

export const toCurrency = (value, locale, currency = 'EUR') => (
  new Intl.NumberFormat(
    LANG_ISO[locale],
    {
      currency,
      style: 'currency',
    },
  ).format(value)
)

export const addThousandsSeparator = num => {
  const parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export const round2Decimals = num => {
  const rounded = Math.round(num * 100) / 100
  return addThousandsSeparator(rounded)
}
