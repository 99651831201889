import * as React from 'react'
import {
  CheckOutlined,
  DownloadOutlined,
  FlagOutlined,
  HddOutlined,
  MoreOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import type { Notification } from 'shared/types'
import { nonNullable } from 'shared/is-types'
import { useI18n } from 'context/I18nProvider'
import BinaryDownloadLink from 'components/Binary/BinaryDownloadLink'
import Button from 'components/UI/Button'
import Dropdown, { MenuInfo } from 'components/UI/Dropdown'
import { API_NOTIFICATIONS_MARK_AS_READ, API_NOTIFICATIONS_MARK_AS_UNREAD } from 'constants/api-v2'
import { useQuery } from 'context/QueryProvider'
import { Action } from 'components/ActionDropdown'

export default function NotificationItem(
  { notification, onUpdate }: {notification: Notification, onUpdate?: Function},
) {
  const { i18n } = useI18n()
  const { client } = useQuery()
  const markAsRead = async (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation()
    if (notification.read) {
      return
    }

    const response = await client.patch(API_NOTIFICATIONS_MARK_AS_READ.replace(':id', String(notification.id)))
    if (onUpdate) {
      await onUpdate(response)
    }
  }
  const markAsUnread = async (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation()
    if (!notification.read) {
      return
    }

    const response = await client.patch(API_NOTIFICATIONS_MARK_AS_UNREAD.replace(':id', String(notification.id)))
    if (onUpdate) {
      await onUpdate(response)
    }
  }

  const actions: Action[] = [
    notification.read ? null : {
      actionKey: 'mark_as_read',
      icon: <CheckOutlined />,
      label: i18n('action.mark_as_read'),
      onClick: ({ domEvent }: MenuInfo) => markAsRead(domEvent),
    },
    !notification.read ? null : {
      actionKey: 'mark_as_unread',
      icon: <CheckOutlined />,
      label: i18n('action.mark_as_unread'),
      onClick: ({ domEvent }: MenuInfo) => markAsUnread(domEvent),
    },
  ].filter(nonNullable)


  let title
  let description
  let icon

  switch (notification.type) {
    case 'new_version':
      title = i18n('notification.new_version.title')
      description = i18n('notification.new_version.description', { version: notification.context.version })
      icon = <FlagOutlined />
      break
    case 'session':
      title = i18n('notification.session.title')
      icon = <HddOutlined />
      break
    case 'import':
      title = i18n('notification.import.title')
      description = i18n('notification.import.description')
      icon = <UploadOutlined />
      break
    case 'export':
      title = i18n('notification.export.title')
      description = notification.context?.success !== true
        ? i18n('notification.export.error_description', {
          error: notification.context?.error ?? i18n('internal.error'),
        })
        : i18n('notification.export.description', { documentName: notification.document?.name })
      icon = <DownloadOutlined />
      break
    default:
      break
  }

  return (
    <div onClick={e => markAsRead(e)}
      onKeyDown={e => e.key === 'Enter' && markAsRead(e)}
      className={`
        flex gap-2 items-center relative cursor-pointer 
        px-4 py-2 transition-colors hover:bg-gray-100
   `}>
      <div className="flex-none">
        <div className="h-12 w-12 min-w-12 rounded-full flex justify-center items-center">
          {icon && (
            <span className="text-2xl text-slate-400">{icon}</span>
          )}
        </div>
      </div>
      <div className="flex-1 break-all" style={{ width: 50 }}>
        <h4 className="text-lg font-semibold leading-tight text-gray-900 mb-0">
          {title}
        </h4>
        {description && (
          <p className="text-sm text-gray-800 mt-2 mb-0">{description}</p>
        )}
        {notification.document && notification.document.url && (
          <BinaryDownloadLink
            href={notification.document.url}
            /*
            // @ts-ignore */
            callback={markAsRead}
            className="pl-0"
          >
            <SaveOutlined />
            {i18n('label.download')}
          </BinaryDownloadLink>
        )}
      </div>
      <div className="flex-none flex justify-center items-center">
        <Dropdown
          items={actions}
          trigger={['click']}
          arrow
          className="px-2"
        >
          <Button
            type="text"
            className="px-2"
            onClick={e => e.stopPropagation()}
          >
            <MoreOutlined />
          </Button>
        </Dropdown>
        <div className="ms-2 h-2 w-2 min-w-2 rounded-full">
          {!notification.read && (
            <div className="h-2 w-2 rounded-full bg-indigo-800" />
          )}
        </div>
      </div>
    </div>
  )
}
