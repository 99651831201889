import { API_URL_LOGIN as URL } from 'constants/api'

export const login = client => (
  async ({ request }) => {
    const formData = await request.formData()
    const payload = Object.fromEntries(formData)

    let resp = null
    let token = null
    let error = null

    try {
      resp = await client.post(URL, payload)
      token = resp.token
    } catch (e) {
      if (e?.response?.status === 401) {
        error = e

        if (!!e?.response?.data?.detail) {
          error = { message: e?.response?.data?.detail }
        }

        if (!!e?.response?.data?.message) {
          error = { message: e?.response?.data?.message }
        }

        if (!!e?.response?.data?.['hydra:description']) {
          error = { message: e?.response?.data?.['hydra:description'] }
        }
      } else {
        error = { message: 'an_error_occured_title' }
      }
    }

    return { error, token }
  }
)
