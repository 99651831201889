import * as React from 'react'
import type { InputProps, InputRef } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import { useLocation } from 'react-router-dom'
import { useI18n } from 'context/I18nProvider'
import Input from 'components/UI/Input'

type SearchInputProps = Omit<InputProps, 'onChange'> & {
  onChange: (txt: string) => void
  withSearchIcon?: boolean
}

const SearchInput = React.forwardRef<InputRef, SearchInputProps>((props, ref) => {
  const { onChange, value, defaultValue, withSearchIcon = true, ...rest } = props
  const { i18n } = useI18n()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const search = searchParams.get('q')

  const debouncedSearch = React.useMemo(() => (
    debounce((text: string) => {
      onChange?.(text)
    }, 300)
  ), [onChange])

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value)
  }, [debouncedSearch])

  return (
    <Input
      ref={ref}
      defaultValue={search || ''}
      addonBefore={withSearchIcon && <SearchOutlined className="text-lg" />}
      onChange={handleChange}
      placeholder={i18n('label.searchplaceholder')}
      allowClear
      outline={false}
      {...rest}
    />
  )
})
export default SearchInput
